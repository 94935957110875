import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import CopyBlock from "../../components/Copy/CopyBlock"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import statPer90 from "../../js/statPer90"


class DefensiveStats extends React.Component {

  render() {

    let mdata = this.props.data.allSheetMessiAdditionalSeasonStats.edges
    let rdata = this.props.data.allSheetRonaldoAdditionalSeasonStats.edges

    // This line is required due to node support issues for Object.fromEntries
    Object.fe = arr => Object.assign({}, ...Array.from(arr, ([k, v]) => ({[k]: v}) ));

    // Map over the two arrays and prefix all the keys with m or r
    mdata = mdata.map(entry => { return Object.fe( Object.entries(entry).map(([key, value]) => [`m${key}`, value] ) ) })
    rdata = rdata.map(entry => { return Object.fe( Object.entries(entry).map(([key, value]) => [`r${key}`, value] ) ) })

    // Combine the two arrays so we have both players' stats in a single object per year/season
    const bothData = mdata.map((item, key) => Object.assign({}, item, rdata[key]))


    const shortSeason = (season) => {
      return season.replace(/-/g, '/').replace(/20/g,'').replace('/21','20/21').replace('19/','19/20')
    }

    const shortComp = (comp) => {
      return comp.replace('Champions League','UCL').replace('All Competitions','League + UCL')
    }

    const comp = (data,p,comp = "All Competitions") => {
      return p == "m" ? data.filter(d => d.mnode.competition === comp) : data.filter(d => d.rnode.competition === comp)
    }


    const mtacklesT = comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.tackles)) }, 0)
    const rtacklesT = comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.tackles)) }, 0)
    const minterceptionsT = comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.interceptions)) }, 0)
    const rinterceptionsT = comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.interceptions)) }, 0)
    const mclearancesT = comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.clearances)) }, 0)
    const rclearancesT = comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.clearances)) }, 0)
    const mshotsBlockedT = comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.shotsBlocked)) }, 0)
    const rshotsBlockedT = comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.shotsBlocked)) }, 0)
    const mpassesBlockedT = comp(bothData,'m').filter(d => d.mnode.passesBlocked !== '-').reduce((total, d) => { return (total + Number(d.mnode.passesBlocked)) }, 0)
    const rpassesBlockedT = comp(bothData,'r').filter(d => d.rnode.passesBlocked !== '-').reduce((total, d) => { return (total + Number(d.rnode.passesBlocked)) }, 0)
    const mcrossesBlockedT = comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.crossesBlocked)) }, 0)
    const rcrossesBlockedT = comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.crossesBlocked)) }, 0)
    const mpressuresT = comp(bothData,'m','League').filter(d => d.mnode.pressures !== '-').reduce((total, d) => { return (total + Number(d.mnode.pressures)) }, 0)
    const rpressuresT = comp(bothData,'r','League').filter(d => d.rnode.pressures !== '-').reduce((total, d) => { return (total + Number(d.rnode.pressures)) }, 0)

    const mminsPlayedT = comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.minsPlayed)) }, 0)
    const rminsPlayedT = comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.minsPlayed)) }, 0)

    return (

      <Layout>
        <SEO
          title={"Defensive Stats - Messi vs Ronaldo"}
          description={`We all know Messi and Ronaldo's attacking stats are out of this world, but what about their defensive stats? Tackles, interceptions, clearances, pressures and more!`}
          canonicalPath={`/detailed-stats/defensive-stats/`}
        />

        <h1><span className="sr-only">Messi vs Ronaldo</span> Defensive Stats</h1>

        

        <div class="mt-8 lg:mt-12">
          <CopyBlock>

            <h2>Messi and Ronaldo Defensive Stats</h2>

            <p>Lionel Messi and Cristiano Ronaldo aren't exactly known for their defending, and the stats prove this perception to be an accurate one. On this page you can find detailed stats for the following actions:</p>

            <ul className="text-highlight underline list-inside">
              <li><AnchorLink to="/detailed-stats/defensive-stats/#tackles" title="Tackles" /></li>
              <li><AnchorLink to="/detailed-stats/defensive-stats/#interceptions" title="Interceptions" /></li>
              <li><AnchorLink to="/detailed-stats/defensive-stats/#clearances" title="Clearances" /></li>
              <li><AnchorLink to="/detailed-stats/defensive-stats/#shots-blocked" title="Shots Blocked" /></li>
              <li><AnchorLink to="/detailed-stats/defensive-stats/#passes-blocked" title="Passes Blocked" /></li>
              <li><AnchorLink to="/detailed-stats/defensive-stats/#crosses-blocked" title="Crosses Blocked" /></li>
              <li><AnchorLink to="/detailed-stats/defensive-stats/#pressing" title="Pressing" /></li>
            </ul>
            
            <h2 id="tackles">Tackles</h2>
            <div className="w-full overflow-x-scroll scrolling-touch hide-scrollbars text-sm">
              <table className="w-full">
                <thead className="text-left">
                <tr>
                  <th className="text-left">Season / Comp</th>
                  <th className="">Messi <span className="sr-only">Tackles</span></th>
                  <th className=""><span className="sr-only">Messi Tackles </span>p90</th>
                  <th className="">Ronaldo <span className="sr-only">Tackles</span></th>
                  <th className=""><span className="sr-only">Ronaldo Tackles </span>p90</th>
                </tr>
                </thead>
                <tbody>
                {bothData.map(
                  d => (
                    <tr key={d.rnode.year+d.rnode.competition} className={`${d.rnode.competition === 'All Competitions' ? 'border-b-2 border-gray-700 font-semibold' : ''}`}>
                      <td className="text-left">{shortSeason(d.rnode.year)} {shortComp(d.rnode.competition)}</td>
                      <td className={` ${Number(d.mnode.tackles) > Number(d.rnode.tackles) ? 'text-highlight' : ''}`}>{d.mnode.tackles}</td>
                      <td className=" border-gray-700 border-r">{statPer90(d.mnode.tackles,d.mnode.minsPlayed)}</td>
                      <td className={` ${Number(d.rnode.tackles) > Number(d.mnode.tackles) ? 'text-highlight' : ''}`}>{d.rnode.tackles}</td>
                      <td className="">{statPer90(d.rnode.tackles,d.rnode.minsPlayed)}</td>
                    </tr>
                  )
                )}
                <tr>
                  <th>Totals</th>
                  <th className={` ${mtacklesT > rtacklesT ? 'text-highlight' : ''}`}>
                    {mtacklesT}
                  </th>
                  <th className="">
                    {statPer90(mtacklesT,mminsPlayedT)}
                  </th>
                  <th className={` ${rtacklesT > mtacklesT ? 'text-highlight' : ''}`}>
                    {rtacklesT}
                  </th>
                  <th className="">
                    {statPer90(rtacklesT,rminsPlayedT)}
                  </th>
                </tr>
                </tbody>
              </table>
            </div>

            <p>Messi edges the tackling stats, making 0.65 tackles per 90 minutes, compared to Ronaldo's 0.38.</p>

            <h2 id="interceptions">Interceptions</h2>
            <div className="w-full overflow-x-scroll scrolling-touch hide-scrollbars text-sm">
              <table className="w-full">
                <thead className="text-left">
                <tr>
                  <th className="text-left">Season / Comp</th>
                  <th className="">Messi <span className="sr-only">Interceptions</span></th>
                  <th className=""><span className="sr-only">Messi Interceptions </span>p90</th>
                  <th className="">Ronaldo <span className="sr-only">Interceptions</span></th>
                  <th className=""><span className="sr-only">Ronaldo Interceptions </span>p90</th>
                </tr>
                </thead>
                <tbody>
                {bothData.map(
                  d => (
                    <tr key={d.rnode.year+d.rnode.competition} className={`${d.rnode.competition === 'All Competitions' ? 'border-b-2 border-gray-700 font-semibold' : ''}`}>
                      <td className="text-left">{shortSeason(d.rnode.year)} {shortComp(d.rnode.competition)}</td>
                      <td className={` ${Number(d.mnode.interceptions) > Number(d.rnode.interceptions) ? 'text-highlight' : ''}`}>{d.mnode.interceptions}</td>
                      <td className=" border-gray-700 border-r">{statPer90(d.mnode.interceptions,d.mnode.minsPlayed)}</td>
                      <td className={` ${Number(d.rnode.interceptions) > Number(d.mnode.interceptions) ? 'text-highlight' : ''}`}>{d.rnode.interceptions}</td>
                      <td className="">{statPer90(d.rnode.interceptions,d.rnode.minsPlayed)}</td>
                    </tr>
                  )
                )}
                <tr>
                  <th>Totals</th>
                  <th className={` ${minterceptionsT > rinterceptionsT ? 'text-highlight' : ''}`}>
                    {minterceptionsT}
                  </th>
                  <th className="">
                    {statPer90(minterceptionsT,mminsPlayedT)}
                  </th>
                  <th className={` ${rinterceptionsT > minterceptionsT ? 'text-highlight' : ''}`}>
                    {rinterceptionsT}
                  </th>
                  <th className="">
                    {statPer90(rinterceptionsT,rminsPlayedT)}
                  </th>
                </tr>
                </tbody>
              </table>
            </div>

            <p>Messi again has the edge in interceptions but only by a tiny margin, making 0.38 interceptions per 90 minutes, compared to Ronaldo's 0.31.</p>

            <h2 id="clearances">Clearances</h2>
            <div className="w-full overflow-x-scroll scrolling-touch hide-scrollbars text-sm">
              <table className="w-full">
                <thead className="text-left">
                <tr>
                  <th className="text-left">Season / Comp</th>
                  <th className="">Messi <span className="sr-only">Clearances</span></th>
                  <th className=""><span className="sr-only">Messi Clearances </span>p90</th>
                  <th className="">Ronaldo <span className="sr-only">Clearances</span></th>
                  <th className=""><span className="sr-only">Ronaldo Clearances </span>p90</th>
                </tr>
                </thead>
                <tbody>
                {bothData.map(
                  d => (
                    <tr key={d.rnode.year+d.rnode.competition} className={`${d.rnode.competition === 'All Competitions' ? 'border-b-2 border-gray-700 font-semibold' : ''}`}>
                      <td className="text-left">{shortSeason(d.rnode.year)} {shortComp(d.rnode.competition)}</td>
                      <td className={` ${Number(d.mnode.clearances) > Number(d.rnode.clearances) ? 'text-highlight' : ''}`}>{d.mnode.clearances}</td>
                      <td className=" border-gray-700 border-r">{statPer90(d.mnode.clearances,d.mnode.minsPlayed)}</td>
                      <td className={` ${Number(d.rnode.clearances) > Number(d.mnode.clearances) ? 'text-highlight' : ''}`}>{d.rnode.clearances}</td>
                      <td className="">{statPer90(d.rnode.clearances,d.rnode.minsPlayed)}</td>
                    </tr>
                  )
                )}
                <tr>
                  <th>Totals</th>
                  <th className={` ${mclearancesT > rclearancesT ? 'text-highlight' : ''}`}>
                    {mclearancesT}
                  </th>
                  <th className="">
                    {statPer90(mclearancesT,mminsPlayedT)}
                  </th>
                  <th className={` ${rclearancesT > mclearancesT ? 'text-highlight' : ''}`}>
                    {rclearancesT}
                  </th>
                  <th className="">
                    {statPer90(rclearancesT,rminsPlayedT)}
                  </th>
                </tr>
                </tbody>
              </table>
            </div>

            <p>Cristiano Ronaldo completely dominates the clearances stats, with his aerial abilities making him a useful tool in defending set pieces. Ronaldo averages 0.61 clearances per 90 minutes, whereas Messi makes around 0.5 clearances per <em>season</em>.</p>


            <h2 id="shots-blocked">Shots Blocked</h2>
            <div className="w-full overflow-x-scroll scrolling-touch hide-scrollbars text-sm">
              <table className="w-full">
                <thead className="text-left">
                <tr>
                  <th className="text-left">Season / Comp</th>
                  <th className="">Messi <span className="sr-only">Shots Blocked</span></th>
                  <th className=""><span className="sr-only">Messi Shots Blocked </span>p90</th>
                  <th className="">Ronaldo <span className="sr-only">Shots Blocked</span></th>
                  <th className=""><span className="sr-only">Ronaldo Shots Blocked </span>p90</th>
                </tr>
                </thead>
                <tbody>
                {bothData.map(
                  d => (
                    <tr key={d.rnode.year+d.rnode.competition} className={`${d.rnode.competition === 'All Competitions' ? 'border-b-2 border-gray-700 font-semibold' : ''}`}>
                      <td className="text-left">{shortSeason(d.rnode.year)} {shortComp(d.rnode.competition)}</td>
                      <td className={` ${Number(d.mnode.shotsBlocked) > Number(d.rnode.shotsBlocked) ? 'text-highlight' : ''}`}>{d.mnode.shotsBlocked}</td>
                      <td className=" border-gray-700 border-r">{statPer90(d.mnode.shotsBlocked,d.mnode.minsPlayed)}</td>
                      <td className={` ${Number(d.rnode.shotsBlocked) > Number(d.mnode.shotsBlocked) ? 'text-highlight' : ''}`}>{d.rnode.shotsBlocked}</td>
                      <td className="">{statPer90(d.rnode.shotsBlocked,d.rnode.minsPlayed)}</td>
                    </tr>
                  )
                )}
                <tr>
                  <th>Totals</th>
                  <th className={` ${mshotsBlockedT > rshotsBlockedT ? 'text-highlight' : ''}`}>
                    {mshotsBlockedT}
                  </th>
                  <th className="">
                    {statPer90(mshotsBlockedT,mminsPlayedT)}
                  </th>
                  <th className={` ${rshotsBlockedT > mshotsBlockedT ? 'text-highlight' : ''}`}>
                    {rshotsBlockedT}
                  </th>
                  <th className="">
                    {statPer90(rshotsBlockedT,rminsPlayedT)}
                  </th>
                </tr>
                </tbody>
              </table>
            </div>

            <p>The numbers are extremely low for both players, but there is a clear margin in favour of Ronaldo. He blocks a shot around 3 times a season, compared to Messi who blocks a shot less than once per season on average.</p>


            <h2 id="passes-blocked">Passes Blocked</h2>
            <div className="w-full overflow-x-scroll scrolling-touch hide-scrollbars text-sm">
              <table className="w-full">
                <thead className="text-left">
                <tr>
                  <th className="text-left">Season / Comp</th>
                  <th className="">Messi <span className="sr-only">Passes Blocked</span></th>
                  <th className=""><span className="sr-only">Messi Passes Blocked </span>p90</th>
                  <th className="">Ronaldo <span className="sr-only">Passes Blocked</span></th>
                  <th className=""><span className="sr-only">Ronaldo Passes Blocked </span>p90</th>
                </tr>
                </thead>
                <tbody>
                {bothData.filter(d => d.mnode.passesBlocked !== '-').map(
                  d => (
                    <tr key={d.rnode.year+d.rnode.competition} className={`${d.rnode.competition === 'All Competitions' ? 'border-b-2 border-gray-700 font-semibold' : ''}`}>
                      <td className="text-left">{shortSeason(d.rnode.year)} {shortComp(d.rnode.competition)}</td>
                      <td className={` ${Number(d.mnode.passesBlocked) > Number(d.rnode.passesBlocked) ? 'text-highlight' : ''}`}>{d.mnode.passesBlocked}</td>
                      <td className=" border-gray-700 border-r">{statPer90(d.mnode.passesBlocked,d.mnode.minsPlayed)}</td>
                      <td className={` ${Number(d.rnode.passesBlocked) > Number(d.mnode.passesBlocked) ? 'text-highlight' : ''}`}>{d.rnode.passesBlocked}</td>
                      <td className="">{statPer90(d.rnode.passesBlocked,d.rnode.minsPlayed)}</td>
                    </tr>
                  )
                )}
                <tr>
                  <th>Totals</th>
                  <th className={` ${mpassesBlockedT > rpassesBlockedT ? 'text-highlight' : ''}`}>
                    {mpassesBlockedT}
                  </th>
                  <th className="">
                    {statPer90(mpassesBlockedT,mminsPlayedT)}
                  </th>
                  <th className={` ${rpassesBlockedT > mpassesBlockedT ? 'text-highlight' : ''}`}>
                    {rpassesBlockedT}
                  </th>
                  <th className="">
                    {statPer90(rpassesBlockedT,rminsPlayedT)}
                  </th>
                </tr>
                </tbody>
              </table>
            </div>

            <p>In terms of passes blocked, Messi comes out on top, blocking 0.36 passes per 90 minutes compared to Ronaldo's 0.24.</p>


            <h2 id="crosses-blocked">Crosses Blocked</h2>
            <div className="w-full overflow-x-scroll scrolling-touch hide-scrollbars text-sm">
              <table className="w-full">
                <thead className="text-left">
                <tr>
                  <th className="text-left">Season / Comp</th>
                  <th className="">Messi <span className="sr-only">Crosses Blocked</span></th>
                  <th className=""><span className="sr-only">Messi Crosses Blocked </span>p90</th>
                  <th className="">Ronaldo <span className="sr-only">Crosses Blocked</span></th>
                  <th className=""><span className="sr-only">Ronaldo Crosses Blocked </span>p90</th>
                </tr>
                </thead>
                <tbody>
                {bothData.map(
                  d => (
                    <tr key={d.rnode.year+d.rnode.competition} className={`${d.rnode.competition === 'All Competitions' ? 'border-b-2 border-gray-700 font-semibold' : ''}`}>
                      <td className="text-left">{shortSeason(d.rnode.year)} {shortComp(d.rnode.competition)}</td>
                      <td className={` ${Number(d.mnode.crossesBlocked) > Number(d.rnode.crossesBlocked) ? 'text-highlight' : ''}`}>{d.mnode.crossesBlocked}</td>
                      <td className=" border-gray-700 border-r">{statPer90(d.mnode.crossesBlocked,d.mnode.minsPlayed)}</td>
                      <td className={` ${Number(d.rnode.crossesBlocked) > Number(d.mnode.crossesBlocked) ? 'text-highlight' : ''}`}>{d.rnode.crossesBlocked}</td>
                      <td className="">{statPer90(d.rnode.crossesBlocked,d.rnode.minsPlayed)}</td>
                    </tr>
                  )
                )}
                <tr>
                  <th>Totals</th>
                  <th className={` ${mcrossesBlockedT > rcrossesBlockedT ? 'text-highlight' : ''}`}>
                    {mcrossesBlockedT}
                  </th>
                  <th className="">
                    {statPer90(mcrossesBlockedT,mminsPlayedT)}
                  </th>
                  <th className={` ${rcrossesBlockedT > mcrossesBlockedT ? 'text-highlight' : ''}`}>
                    {rcrossesBlockedT}
                  </th>
                  <th className="">
                    {statPer90(rcrossesBlockedT,rminsPlayedT)}
                  </th>
                </tr>
                </tbody>
              </table>
            </div>

            <p>Again, the numbers are extremely low here, with Ronaldo blocking 11 crosses in 12 seasons, whilst Messi has just 1 blocked cross to his name.</p>


            <h2 id="pressing">Pressing</h2>
            <div className="w-full overflow-x-scroll scrolling-touch hide-scrollbars text-sm">
              <table className="w-full">
                <thead className="text-left">
                <tr>
                  <th className="text-left">Season / Comp</th>
                  <th className="">Messi <span className="sr-only">Pressures</span></th>
                  <th className=""><span className="sr-only">Messi Pressures </span>p90</th>
                  <th className="">Ronaldo <span className="sr-only">Pressures</span></th>
                  <th className=""><span className="sr-only">Ronaldo Pressures </span>p90</th>
                </tr>
                </thead>
                <tbody>
                {bothData.filter(d => d.rnode.pressures !== '-').map(
                  d => (
                    <tr key={d.rnode.year+d.rnode.competition}>
                      <td className="text-left">{shortSeason(d.rnode.year)} {shortComp(d.rnode.competition)}</td>
                      <td className={` ${Number(d.mnode.pressures) > Number(d.rnode.pressures) ? 'text-highlight' : ''}`}>{d.mnode.pressures}</td>
                      <td className=" border-gray-700 border-r">{statPer90(d.mnode.pressures,d.mnode.minsPlayed)}</td>
                      <td className={` ${Number(d.rnode.pressures) > Number(d.mnode.pressures) ? 'text-highlight' : ''}`}>{d.rnode.pressures}</td>
                      <td className="">{statPer90(d.rnode.pressures,d.rnode.minsPlayed)}</td>
                    </tr>
                  )
                )}
                <tr>
                  <th>Totals</th>
                  <th className={` ${mpressuresT > rpressuresT ? 'text-highlight' : ''}`}>
                    {mpressuresT}
                  </th>
                  <th className="">
                    {statPer90(mpressuresT,bothData.filter(d => d.mnode.competition === "League" && d.mnode.pressures !== '-').reduce((total, d) => { return (total + Number(d.mnode.minsPlayed)) }, 0))}
                  </th>
                  <th className={` ${rpressuresT > mpressuresT ? 'text-highlight' : ''}`}>
                    {rpressuresT}
                  </th>
                  <th className="">
                    {statPer90(rpressuresT,bothData.filter(d => d.rnode.competition === "League" && d.rnode.pressures !== '-').reduce((total, d) => { return (total + Number(d.rnode.minsPlayed)) }, 0))}
                  </th>
                </tr>
                </tbody>
              </table>
            </div>

            <p>In terms of pressing, it's Messi who comes out on top, making over 10 pressures per 90 minutes in the league since 2017/18, whereas Ronaldo has made around 8 pressures per 90 in this timeframe.</p>

            <p>Pressing stats are via StatsBomb. All other stats are Opta.</p>

          </CopyBlock>
        </div>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAdditionalSeasonStats {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          tackles
          interceptions
          clearances
          shotsBlocked
          crossesBlocked
          passesBlocked
          pressures
        }
      }
    }
    allSheetRonaldoAdditionalSeasonStats {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          tackles
          interceptions
          clearances
          shotsBlocked
          crossesBlocked
          passesBlocked
          pressures
        }
      }
    }
  }
`

export default DefensiveStats
